import * as _ from "lodash";
import { isEmpty } from "lodash";

const formatSecondsPart = (totalSeconds) => {
  const secondsPart = (totalSeconds % 60).toString();
  return secondsPart.length === 2 ? secondsPart : secondsPart.padStart(2, "0");
};

export const formatTimeRemainingDisplay = (secsLeft) => {
  const formattedTimeRemaining =
    secsLeft === Infinity
      ? "\u221E" //infinity symbol
      : `${Math.floor(secsLeft / 60)}:${formatSecondsPart(secsLeft)}`;
  return formattedTimeRemaining;
};

export const uppercaseOnlyFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const mapRight = (arr, fn) => {
  const result = [];
  _.eachRight(arr, (...args) => {
    result.push(fn(...args));
  });
  return result;
};

export const anyValsDiffer = (obj1, obj2, keys) => {
  for (var i = 0; i < keys.length; i++) {
    if (obj1[keys[i]] !== obj2[keys[i]]) {
      return true;
    }
  }
  return false;
};

export const toObj = (
  arr,
  keySelector = _.identity,
  valSelector = _.identity
) => {
  return arr.reduce((acc, curr, idx) => {
    acc[keySelector(curr, idx)] = valSelector(curr, idx);
    return acc;
  }, {});
};

export const toObjHash = (arr, keySelector = _.identity) =>
  // @ts-ignore
  toObj(arr, keySelector, () => true);

//returns true if given arrays have the same elements (compared with ===), false otherwise.
export const arraysHaveSameElements = (arr1, arr2) => {
  const arr1Hash = toObjHash(arr1);
  return arr1.length === arr2.length && arr2.every((el) => arr1Hash[el]);
};

export const fromEntries =
  // @ts-ignore
  Object.fromEntries ||
  ((entries) => {
    return entries.reduce((acc, curr) => {
      acc[curr[0]] = curr[1];
      return acc;
    }, {});
  });

export const filterObj = (obj, fn) => {
  return fromEntries(Object.entries(obj).filter(fn));
};
export const mapObj = (obj, fn) => {
  return fromEntries(Object.entries(obj).map(fn));
};

export const immutableReverse = (arr) => {
  const copy = arr.slice();
  _.reverse(copy);
  return copy;
};

export const parseJSONIfExists = (possibleJSON) =>
  possibleJSON ? JSON.parse(possibleJSON) : null;
export const ranks = ["1", "2", "3", "4", "5", "6", "7", "8"];
export const files = ["a", "b", "c", "d", "e", "f", "g", "h"];

export const getOrderedCoordSequenceForRanges = (
  fileIdxRange,
  rankIdxRange
) => {
  const ordered = rankIdxRange.flatMap((nextRankIdx) => {
    return fileIdxRange.map((nextFileIdx) => {
      return [files[nextFileIdx], ranks[nextRankIdx]];
    });
  });

  return ordered;
};

export const getRandomCoordSequenceForRanges = (fileIdxRange, rankIdxRange) => {
  const shuffled = _.shuffle(
    getOrderedCoordSequenceForRanges(fileIdxRange, rankIdxRange)
  );
  return shuffled;
};

export const buildInitialSquareStats = () => {
  const strCoordSequence = getOrderedCoordSequenceForRanges(
    _.range(8),
    _.range(8)
  ).map((coord) => coord.join(""));

  // @ts-ignore
  return toObj(strCoordSequence, _.identity, () => {
    //time to answer score
    return { latestTTAs: [5000, 5000, 5000], ttaBand: "8", timesAnswered: 0 };
  });
};

// export const inclusiveBetween = (num, min, max) => {
//   return num >= min && num <= max;
// };

// @ts-ignore

//weights normalized to (roughly) sum to 100.
export const getWeightedRandomVal = (currChoices, choiceValsToWeights) => {
  const existingChoiceValsToWeights = filterObj(choiceValsToWeights, ([k, v]) =>
    currChoices.includes(k)
  );

  if (_.isEmpty(existingChoiceValsToWeights)) {
    throw new Error(
      "Passed values array have at least one existing value from the weights object."
    );
  }
  const sumOfWeights = _.sum(Object.values(existingChoiceValsToWeights));

  const choiceValsWithNormalizedWeights = Object.entries(
    existingChoiceValsToWeights
  ).map(([k, v]) => {
    const normalizedWeight = v / sumOfWeights;
    return { choiceVal: k, weight: normalizedWeight };
  });

  const choiceValsToRanges = choiceValsWithNormalizedWeights.reduce(
    (acc, { choiceVal, weight }, idx, fullArr) => {
      const currInclusiveMin =
        idx === 0 ? 0 : acc[fullArr[idx - 1].choiceVal].exclusiveMax;
      const currExclusiveMax = currInclusiveMin + weight;
      return {
        ...acc,
        [choiceVal]: {
          inclusiveMin: currInclusiveMin,
          exclusiveMax: currExclusiveMax,
        },
      };
    },
    {}
  );


  const randSeed = Math.random();
  const selectedChoice = currChoices.find((currChoiceVal) => {
    const range = choiceValsToRanges[currChoiceVal];
    return _.inRange(randSeed, range.inclusiveMin, range.exclusiveMax);
  });

  return selectedChoice;
};

/*
quadrant numbering from white's perspective:
  3 | 4
  -   -
  1 | 2
*/
export const getRandomCoordSequenceForQuadrant = (quadNum) => {
  const byQuadNum = {
    1: [_.range(4), _.range(4)],
    2: [_.range(4, 8), _.range(4)],
    3: [_.range(4), _.range(4, 8)],
    4: [_.range(4, 8), _.range(4, 8)],
  };

  const [fileIdxRange, rankIdxRange] = byQuadNum[quadNum];
  return getRandomCoordSequenceForRanges(fileIdxRange, rankIdxRange);
};

export const getRandomCoordSequence = (chunkedByQuadrant = false) => {
  if (chunkedByQuadrant) {
    const quadSequencesInOrder = [1, 2, 3, 4].map(
      getRandomCoordSequenceForQuadrant
    );
    const shuffledQuadSequences = _.shuffle(quadSequencesInOrder);
    // @ts-ignore
    return shuffledQuadSequences.flat();
  } else {
    return getRandomCoordSequenceForRanges(_.range(8), _.range(8));
  }
};
