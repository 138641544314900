import React from "react";
import "./App.scss";
import * as _ from "lodash";
import * as workerTimers from "worker-timers";
import clsx from "clsx";
import {
  getRandomCoordSequence,
  buildInitialSquareStats,
  parseJSONIfExists,
  ranks,
  files,
  formatTimeRemainingDisplay,
  uppercaseOnlyFirstLetter,
  mapRight,
  anyValsDiffer,
  mapObj,
  getWeightedRandomVal,
  filterObj,
  immutableReverse,
  toObj,
} from "./utils";

const WHITE = "white",
  BLACK = "black",
  CLICK_SQUARE = "CLICK_SQUARE",
  TYPE_COORD = "TYPE_COORD",
  RANDOM_REPETION = "RANDOM_REPETION",
  NO_REPETITION = "NO_REPETITION",
  //TODO(leo): vvv add below
  CHUNKED_BY_QUADRANT_REPETITION = "CHUNKED_BY_QUADRANT_REPETITION",
  HARDER_BIAS_REPETITION = "HARDER_BIAS_REPETITION",
  DEFAULT_TIME_LIMIT = 30,
  DEFAULT_REPETIION_MODE = HARDER_BIAS_REPETITION,
  MAX_VOL = 0.04;

const getDefaultUserPrefs = () => {
  const defaultUserPrefs = {
    muted: false,
    orientation: WHITE,
    showCoordinates: true,
    drillMode: CLICK_SQUARE,
    repetitionMode: DEFAULT_REPETIION_MODE,
    timeLimit: DEFAULT_TIME_LIMIT,
    coordSequence: getRandomCoordSequence(
      DEFAULT_REPETIION_MODE === CHUNKED_BY_QUADRANT_REPETITION
    ),
    squareStats: buildInitialSquareStats(),
  };
  return defaultUserPrefs;
};

const formatDrillMode = (drillMode) => {
  return drillMode === TYPE_COORD ? "Type Coord" : "Click Square";
};

const coordsAreEqual = (c1 = ["", ""], c2 = ["", ""]) => {
  return (
    c1[0].toLowerCase() === c2[0].toLowerCase() &&
    c1[1].toLowerCase() === c2[1].toLowerCase()
  );
};

const getPbKey = ({ drillMode, orientation, timeLimit }) => {
  return [drillMode, orientation, timeLimit]
    .filter(_.identity)
    .map(String)
    .join("");
};

const Square = ({
  color,
  rank,
  file,
  highlight,
  selectSquare,
  showRank,
  showFile,
}) => {
  return (
    <div
      className={clsx(`square`, color, highlight && "highlight", selectSquare && 'clickable-square')}
      onClick={selectSquare ? () => selectSquare(file, rank) : _.noop}
    >
      {showRank && <div className="rank">{rank}</div>}
      {showFile && <div className="file">{file}</div>}
    </div>
  );
};

const getMeanTTA = (statEl) => _.mean(statEl.latestTTAs);

const clearLegacyPrefs = () => {
  const existingPrefs = parseJSONIfExists(localStorage.getItem("userPrefs"));
  if (existingPrefs && !existingPrefs.hasOwnProperty("timeLimit")) {
    localStorage.removeItem("userPrefs");
    localStorage.removeItem("personalBestData");
  }
};
clearLegacyPrefs();

class App extends React.Component {
  constructor(props) {
    super(props);

    const storedPrefs = this.getInitialUserPrefs();

    const storedPbData = this.getInitialPbData();

    this.coordInputRef = React.createRef();

    this.state = {
      isRunning: false,
      score: 0,
      currAnswer: [],
      status: "",
      inputCoord: "",
      showAnswer: false,
      personalBestData: storedPbData,
      orientation: storedPrefs.orientation,
      timeLimit: storedPrefs.timeLimit,
      secondsLeft: storedPrefs.timeLimit,
      muted: storedPrefs.muted,
      showCoordinates: storedPrefs.showCoordinates,
      drillMode: storedPrefs.drillMode,
      repetitionMode: storedPrefs.repetitionMode,
      coordSequence: storedPrefs.coordSequence,
      squareStats: storedPrefs.squareStats || buildInitialSquareStats(),
    };
  }

  getNextRandomCoord = () => {
    const {
      repetitionMode,
      coordSequence,
      squareStats,
      currAnswer,
    } = this.state;

    let finalResult;

    const sequenceToUse =
      coordSequence.length > 0
        ? coordSequence
        : getRandomCoordSequence(
            repetitionMode === CHUNKED_BY_QUADRANT_REPETITION
          );

    const getFullyRandomCoord = () => {
      const randResult = _.sample(sequenceToUse);
      return randResult;
    };

    if (
      [CHUNKED_BY_QUADRANT_REPETITION, NO_REPETITION].includes(repetitionMode)
    ) {
      //get next in sequence, re-store sequence without that one
      const randResult = sequenceToUse[0];
      this.setState({ coordSequence: sequenceToUse.slice(1) });
      finalResult = randResult;
    } else if (repetitionMode === HARDER_BIAS_REPETITION) {
      //10% of time, go fully random
      if (Math.random() < 0.1) {
        finalResult = getFullyRandomCoord();
      } else {
        const bandWeights = {
          8: 6,
          7: 4,
          6: 3,
          5: 2,
          4: 0.5,
          3: 0.5,
          2: 0.5,
          1: 0.5,
        };

        const bandToChooseFrom = getWeightedRandomVal(
          //only select from existing bands
          _.uniq(Object.values(squareStats).map((ss) => ss.ttaBand)),
          bandWeights
        );

        const coordsForBand = Object.keys(
          filterObj(squareStats, ([k, v]) => v.ttaBand === bandToChooseFrom)
        );

        const coordResult = _.sample(coordsForBand).split("");
        finalResult = coordResult;
      }
    } else if (repetitionMode === RANDOM_REPETION) {
      finalResult = getFullyRandomCoord();
    }

    //prevent an immediate repeat
    if (_.isEqual(finalResult, currAnswer)) {
      finalResult = this.getNextRandomCoord();
    }

    return finalResult;
  };

  focusCoordInput = () => {
    if (this.coordInputRef.current) {
      this.coordInputRef.current.focus();
    }
  };

  getInitialPbData = () => {
    return parseJSONIfExists(localStorage.getItem("personalBestData")) || {};
  };
  getInitialUserPrefs = () => {
    const rawInitialPrefsObj =
      parseJSONIfExists(localStorage.getItem("userPrefs")) ||
      getDefaultUserPrefs();

    const finalInitialPrefsObj = {
      ...rawInitialPrefsObj,
      timeLimit: Number(rawInitialPrefsObj.timeLimit),
    };
    return finalInitialPrefsObj;
  };

  componentDidMount() {
    ["start", "end", "correct", "incorrect", "failure", "success"].forEach(
      (id) => {
        const audio = document.getElementById(id);
        audio.volume =
          id === "correct"
            ? MAX_VOL * 2
            : id === "start"
            ? MAX_VOL * 0.5
            : MAX_VOL;
      }
    );
    //TODO(leo): revert below
    // this.getBandMaxTTACutoffs();
  }

  tick = () => {
    const newVal = this.state.secondsLeft - 1;
    this.setState({ secondsLeft: newVal });
  };

  playSound = (id) => {
    if (!this.state.muted) {
      const audio = document.getElementById(id);
      if (audio.paused) {
        audio.play();
      } else {
        audio.currentTime = 0;
      }
    }
  };
  muteAll = (id) => {
    ["start", "end", "correct", "incorrect", "failure", "success"].forEach(
      (id) => {
        const audio = document.getElementById(id);
        if (!audio.paused) {
          audio.pause();
        }
      }
    );
  };

  startTimer = () => {
    //don't have a zeroth tick
    if (this.state.secondsLeft > 1) {
      if (!this.state.isRunning) {
        this.playSound("start");
        this.setState({
          currAnswer: this.getNextRandomCoord(),
          currSquareStartTime: performance.now(),
          score: 0,
        });
      } else {
        //don't tick immediately the first time
        this.tick();
      }
      const timeout = workerTimers.setTimeout(this.startTimer, 1000);
      this.setState({ currTimeout: timeout, isRunning: true });
    } else {
      this.endPeriod(true);
    }
  };

  storeCurrentPrefs = () => {
    const {
      orientation,
      muted,
      showCoordinates,
      drillMode,
      repetitionMode,
      coordSequence,
      squareStats,
      timeLimit,
    } = this.state;
    const newPrefs = {
      orientation,
      muted,
      showCoordinates,
      drillMode,
      repetitionMode,
      coordSequence,
      squareStats,
      timeLimit: String(timeLimit), //account for Infinity value,
      //which can't be converted to JSON as a number (gets stored as null)
    };
    localStorage.setItem("userPrefs", JSON.stringify(newPrefs));
  };

  componentDidUpdate(_prevProps, prevState) {
    if (
      anyValsDiffer(this.state, prevState, [
        "orientation",
        "muted",
        "showCoordinates",
        "drillMode",
        "repetitionMode",
        "coordSequence",
        "squareStats",
        "timeLimit",
      ])
    ) {
      this.storeCurrentPrefs();
    }
  }

  getPbForCurrSettings = () => {
    const { drillMode, orientation, timeLimit, personalBestData } = this.state;
    const pbKey = getPbKey({ drillMode, orientation, timeLimit });
    const currPb = personalBestData[pbKey];
    return currPb || 0;
  };

  setNewPb = () => {
    const {
      orientation,
      personalBestData,

      score,
      drillMode,
      timeLimit,
    } = this.state;

    const pbKey = getPbKey({ drillMode, orientation, timeLimit });

    const newPbData = {
      ...personalBestData,
      [pbKey]: score,
    };
    localStorage.setItem("personalBestData", JSON.stringify(newPbData));
    this.setState({ personalBestData: newPbData });
  };

  endPeriod = (withSound) => {
    const { isRunning, currTimeout, score, timeLimit } = this.state;
    if (isRunning) {
      workerTimers.clearTimeout(currTimeout);
      const newSecondsLeftVal = timeLimit;
      const finalScore = score;

      const newRecord = this.getPbForCurrSettings() < finalScore;

      if (newRecord) {
        this.setNewPb();
      }

      this.setState({
        status: "",
        inputCoord: "",
        currTimeout: null,
        isRunning: false,
        secondsLeft: newSecondsLeftVal,
      });
      if (withSound) {
        this.playSound(newRecord ? "success" : "end");
      }
    }
  };

  // getBandMaxTTACutoffs = () => {
  //   const { squareStats } = this.state;

  //   const meanTTAs = Object.values(squareStats).map(getMeanTTA);
  //   const minMeanTTA = Math.min(...meanTTAs);
  //   const maxMeanTTA = Math.max(...meanTTAs);

  //   const meanRange = maxMeanTTA - minMeanTTA;

  //   const bandMaxTTACutoffs = _.range(1, 9).map((bandNum) => {
  //     const maxTTAForBand = minMeanTTA + (bandNum / 8) * meanRange;
  //     return { band: String(bandNum), maxTTAForBand };
  //   });

  //   Object.values(squareStats).forEach((v) => {
  //     const meanTTA = getMeanTTA(v);

  //     const newBandObj = bandMaxTTACutoffs.find((bObj, idx) => {
  //       return meanTTA <= bObj.maxTTAForBand;
  //     });
  //     if (!newBandObj) {
  //       debugger;
  //     }
  //   });
  // };

  //calc new band, normalize to not decrease too much
  //TODO(leo): vvv calc if the bands ever get out of order due to norming?
  //TODO(leo): vvv double check cb fires after new val
  updateSquareStats = (f, r, cb) => {
    const squareKey = [f, r].join("");
    const { currSquareStartTime, squareStats } = this.state;

    const currTTA = performance.now() - currSquareStartTime;
    const currSquareStats = squareStats[squareKey];

    const newLatestTTAs = [currTTA]
      .concat(currSquareStats.latestTTAs)
      .slice(0, 3);

    const newCurrSquareStats = {
      ...currSquareStats,
      latestTTAs: newLatestTTAs,
      timesAnswered: currSquareStats.timesAnswered + 1,
    };

    const newOverallSquareStats = {
      ...squareStats,
      [squareKey]: newCurrSquareStats,
    };

    const meanTTAs = Object.values(newOverallSquareStats).map(getMeanTTA);
    const minMeanTTA = Math.min(...meanTTAs);
    const maxMeanTTA = Math.max(...meanTTAs);

    const meanRange = maxMeanTTA - minMeanTTA;

    const bandMaxTTACutoffs = _.range(1, 9).map((bandNum) => {
      const maxTTAForBand = minMeanTTA + (bandNum / 8) * meanRange;
      return { band: String(bandNum), maxTTAForBand };
    });

    const newFinalOverallSquareStats = mapObj(
      newOverallSquareStats,
      ([k, v]) => {
        const meanTTA = getMeanTTA(v);
        //find lowest band where this square's mean TTA is below or equal the max

        const newBandObj = bandMaxTTACutoffs.find((bObj, idx) => {
          return meanTTA <= bObj.maxTTAForBand;
        });
        if (!newBandObj) {
          /*TODO(leo): err on h8 (
        
          h8: {latestTTAs: [1010.2900000056252, 1363.0750000011176, 1072.0150000124704], ttaBand: "1",…}
          latestTTAs: [1010.2900000056252, 1363.0750000011176, 1072.0150000124704]
          timesAnswered: 3
          ttaBand: "1"

        ) where could not be found below. rounding error?
        */
          debugger;
          //just skip updating any stats on this one for now until cause of error is found.
          return [k, { ...this.state.squareStats[k] }];
        } else {
          const newBand = newBandObj.band;

          return [k, { ...v, ttaBand: newBand }];
        }
      }
    );

    //strip any gaps/missing bands
    const newExistingBands = _.uniq(
      Object.values(newFinalOverallSquareStats).map((el) => el.ttaBand)
    );

    const existingBandsDesc = immutableReverse(_.sortBy(newExistingBands));
    const existingBandsToNormalizedBands = toObj(
      existingBandsDesc,
      _.identity,
      (eb, idx) => {
        return `${8 - idx}`;
      }
    );

    const newFinalOverallSquareStatsWithBands = mapObj(
      newFinalOverallSquareStats,
      ([k, v]) => {
        return [
          k,
          { ...v, ttaBand: existingBandsToNormalizedBands[v.ttaBand] },
        ];
      }
    );

    this.setState({ squareStats: newFinalOverallSquareStatsWithBands }, cb);
  };

  selectSquare = (f, r) => {
    if (this.state.isRunning && !this.state.showAnswer) {
      const isCorrect = coordsAreEqual([f, r], this.state.currAnswer);
      if (isCorrect) {
        this.playSound("correct");
        this.updateSquareStats(...this.state.currAnswer, () => {
          this.setState({
            score: this.state.score + 1,
            status: "Correct!",
            //do in callback to ensure latest stats used for nextRandomCoord
            currAnswer: this.getNextRandomCoord(),
            currSquareStartTime: performance.now(),
          });
        });
      } else {
        //TODO(leo): vvv delete if unused. make them stay on this one until they get it right.
        this.setState({ status: "Incorrect!",  });
        // this.setState({ status: "Incorrect!", showAnswer: true });
        this.playSound("incorrect");

        // setTimeout(() => {
        //   //ends up adding a 2s penalty to TTA for incorrect answer
        //   this.updateSquareStats(...this.state.currAnswer, () => {
        //     this.setState({
        //       status: "",
        //       showAnswer: false,
        //       currAnswer: this.getNextRandomCoord(),
        //       currSquareStartTime: performance.now(),
        //     });
        //     if (this.state.drillMode === TYPE_COORD) {
        //       this.focusCoordInput();
        //     }
        //   });
        // }, 2000);
      }
    }
  };
  render() {
    const {
      orientation,
      isRunning,
      secondsLeft,
      muted,
      score,
      status,
      showAnswer,
      currAnswer,
      showCoordinates,
      drillMode,
      inputCoord,
      timeLimit,
      repetitionMode,
    } = this.state;

    const isUnlimitedTime = secondsLeft === Infinity;
    return (
      <div className="App">
        <h2 className="title">Chess Coordinate Trainer</h2>
        <div className="main-content">
          <div className="board">
            {(orientation === WHITE ? mapRight : _.map)(ranks, (r, rIdx) => {
              return (orientation === WHITE ? _.map : mapRight)(
                files,
                (f, fIdx) => {
                  const color = rIdx % 2 === fIdx % 2 ? BLACK : WHITE;
                  let highlight = false;
                  if (showAnswer || (drillMode === TYPE_COORD && isRunning)) {
                    highlight = coordsAreEqual(currAnswer, [f, r]);
                  }
                  return (
                    <Square
                      {...{
                        color,
                        highlight,
                        rank: r,
                        file: f,
                        key: r + f,
                        selectSquare:
                          drillMode === CLICK_SQUARE
                            ? this.selectSquare
                            : null,
                        showRank:
                          showCoordinates &&
                          (orientation === WHITE ? fIdx === 0 : fIdx === 7),
                        showFile:
                          showCoordinates &&
                          (orientation === WHITE ? rIdx === 0 : rIdx === 7),
                      }}
                    />
                  );
                }
              );
            })}
          </div>

          <div className="controls">
            {drillMode === CLICK_SQUARE ? (
              <>
                {isRunning && !_.isEmpty(currAnswer) && (
                  <h1 className="curr-answer">{currAnswer.join("")}</h1>
                )}
              </>
            ) : (
              isRunning && (
                <div className="square-input">
                  <input
                    ref={this.coordInputRef}
                    placeholder="Enter coordinate..."
                    autoFocus={true}
                    disabled={showAnswer}
                    autoComplete="nope"
                    value={inputCoord}
                    onChange={({ target: { value } }) => {
                      if (value && value.trim().length === 2) {
                        this.setState({ inputCoord: "" });

                        this.selectSquare(value[0], value[1]);
                      } else {
                        this.setState({ inputCoord: value });
                      }
                    }}
                  />
                </div>
              )
            )}
            {isRunning && (
              <h4
                className={clsx(
                  "status",
                  status === "Correct!" ? "correct" : "incorrect"
                )}
              >
                {status}{" "}
                {drillMode === TYPE_COORD && showAnswer && currAnswer.join("")}
              </h4>
            )}
            {isRunning && !isUnlimitedTime && (
              <h4>Time Left: {formatTimeRemainingDisplay(secondsLeft)}</h4>
            )}
            {(!!score || isRunning) && <h4>Score: {score}</h4>}
            {!isRunning && !isUnlimitedTime && (
              <h4>Personal Best: {this.getPbForCurrSettings()}</h4>
            )}
            {isRunning ? (
              <button
                className="btn cancel"
                onClick={() => this.endPeriod(!isUnlimitedTime)}
              >
                Stop
              </button>
            ) : (
              <button className="btn start" onClick={this.startTimer}>
                Start
              </button>
            )}
            <div className="prefs">
              <div className="control">
                Drill Mode
                <select
                  disabled={isRunning}
                  value={drillMode}
                  onChange={({ target: { value } }) =>
                    this.setState({ drillMode: value, score: 0 })
                  }
                >
                  <option value={CLICK_SQUARE}>
                    {formatDrillMode(CLICK_SQUARE)}
                  </option>
                  <option value={TYPE_COORD}>
                    {formatDrillMode(TYPE_COORD)}
                  </option>
                </select>
              </div>
              <div className="control">
                Orientation
                <select
                  disabled={isRunning}
                  value={orientation}
                  onChange={({ target: { value } }) =>
                    this.setState({ orientation: value, score: 0 })
                  }
                >
                  <option value={WHITE}>
                    {uppercaseOnlyFirstLetter(WHITE)}
                  </option>
                  <option value={BLACK}>
                    {uppercaseOnlyFirstLetter(BLACK)}
                  </option>
                </select>
              </div>
              <div className="control">
                Time Limit
                <select
                  disabled={isRunning}
                  value={String(timeLimit)}
                  onChange={({ target: { value } }) => {
                    const newLimit = Number(value);
                    this.setState({
                      timeLimit: newLimit,
                      secondsLeft: newLimit,
                      score: 0,
                    });
                  }}
                >
                  <option value={"30"}>30 seconds</option>
                  <option value={"60"}>1 minute</option>
                  <option value={"Infinity"}>Unlimited</option>
                </select>
              </div>
              <div className="control">
                Repetition
                <select
                  disabled={isRunning}
                  value={repetitionMode}
                  onChange={({ target: { value } }) => {
                    this.setState({
                      repetitionMode: value,
                      coordSequence: getRandomCoordSequence(
                        value === CHUNKED_BY_QUADRANT_REPETITION
                      ),
                    });
                  }}
                >
                  <option value={HARDER_BIAS_REPETITION}>
                    Weight By Difficulty
                  </option>
                  <option value={RANDOM_REPETION}>Fully Random</option>
                  <option value={NO_REPETITION}>No Repeats</option>
                  {/* //TODO(leo): vvv revisit */}
                  {/* <option value={CHUNKED_BY_QUADRANT_REPETITION}>
                    By Quadrant
                  </option> */}
                </select>
              </div>
            </div>
            <div className="prefs other-prefs">
              <div className="control">
                Show Coords
                <input
                  type="checkbox"
                  checked={showCoordinates}
                  onChange={({ target: { checked } }) =>
                    this.setState({ showCoordinates: checked })
                  }
                />
              </div>
              <div className="control">
                Mute Sounds
                <input
                  type="checkbox"
                  checked={muted}
                  onChange={({ target: { checked } }) => {
                    this.muteAll();
                    this.setState({ muted: checked });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
